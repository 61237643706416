//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FooterTop from './footer/footerTop.vue'
import SingleImage from './home/SingleImage.vue'
import TopScreen from './TopScreen.vue'
import AnimatedLink from './common/AnimatedLink.vue'
import Partners from './home/Partners.vue'

export default {
  components: {
    TopScreen,
    SingleImage,
    FooterTop,
    AnimatedLink,
    Partners
  },
  data () {
    return {
    }
  }
}
