//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimatedLink from '../common/AnimatedLink.vue'

export default {
  components: { AnimatedLink },
  props: {
    imgPath: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    linkText: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isReady: false
    }
  },
  mounted () {
    this.isReady = true
  }
}
